<template>
  <checklist-form :checklistId="checklistId" :formId="formId" @update="onSuccessHandler" :token="accessToken" class="pa-1"></checklist-form>
</template>
<script>
export default {
  props: ['formId', 'formName', 'checklistId', 'module', 'propId'],
  data () {
    return {
      reInit: 0,
      accessToken: process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN,
      moduleId: this.$route.params.id
    }
  },
  components: {
    'checklist-form': () => import('./ChecklistForm.vue')
  },
  methods: {
    onSuccessHandler (payload) {
      if (payload.status === 'success') {
        let text = ''
        if (payload.data && payload.action === 'update') text = 'updatedSuccess'
        else if (payload.data && payload.action === 'add') text = 'addedSucess'
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        if (payload.action === 'add') this.saveChecklistData(payload.data, payload.action)
        else if (payload.action === 'update') this.saveChecklistData(payload.data, payload.action)
      } else if (payload.status === 'error' && ((payload.data && payload.data.response.data))) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: payload.data.response.data })
      }
    },
    saveChecklistData (checklist, action) {
      const formName = this.formName
      const recordId = this.$route.params[this.propId]
      let url = ''
      let method = ''
      let text = ''
      if (checklist._id && action === 'update') {
        url = `checklists/${checklist._id}`
        method = 'put'
        text = 'updatedSuccess'
      } else {
        url = 'checklists'
        method = 'post'
        text = 'addedSucess'
      }
      if (method === 'post') {
        const checklistObj = { module_id: recordId, form_id: checklist.form_id, checklist_id: checklist._id, name: formName, module: this.moduleId }
        this.$api.execute(method, url, checklistObj)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            if (response.data) {
              this.$eventBus.$emit('setChecklistId', response.data.checklist_id)
              this.$root.$emit('closeDialog')
            }
          })
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$root.$emit('closeDialog')
      }
    }
  }
}
</script>
